<template>
  <div>
    <a-layout>
      <a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
        <div>
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>

  </div>
</template>

<script>
export default {

  data() {
    return {
      showForm: false
    }
  },
  created() {
  },
  methods: {
    goToUrl(index) {
        this.$router.push({path:this.menus[index].link});
    },
  }
}
</script>

<style>

</style>
